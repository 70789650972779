import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initializeApp } from "firebase/app";
import reportWebVitals from './reportWebVitals';
import Sidebar from './components/Sidebar';
import Login from './pages/auth/Login';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import {store, persistor} from './redux/store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './pages/dashboard/Dashboard';
import Settings from './pages/settings/Settings';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Rides from './pages/rides/Rides';
import RideInfo from './pages/rides/RideInfo';
import Vehicles from './pages/vehicles/Vehicles';
import Admin from './pages/admin/Admin';
import Vendors from './pages/vendors/Vendors';
import Users from './pages/user/Users';
import UserInfo from './pages/user/UserInfo';
import Cities from './pages/settings/cities/Cities';
import Brands from './pages/settings/brands/Brands';
import Preferences from './pages/settings/preferences/Preferences';
import PickupPoints from './pages/settings/pickup-points/PickupPoints';
import VehicleInfo from './pages/vehicles/VehicleInfo';
import UserRides from './pages/user/UserRides';
import UserPayments from './pages/user/UserPayments';
import UserProfile from './pages/user/UserProfile';
import VehicleInformation from './pages/vehicles/VehicleInformation';
import Payments from './pages/payments/Payments';
import PaymentInfo from './pages/payments/PaymentInfo';
import Memebership from './pages/settings/membership/Membership';
import Membership from './pages/membership/Membership';
import MembershipInfo from './pages/membership/MembershipInfo';
import Dues from './pages/dues/Dues';
import DueInfo from './pages/dues/DueInfo';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { firebaseConfig } from './config/auth';
import Offers from './pages/offers/Offers';

const router = createBrowserRouter([
  {
    path:'/login',
    element:<Login/>
  },
  {
    path:'/',
    element:<Sidebar/>,
    children:[
      {
        path:'/',
        index:true,
        element:<Dashboard/>
        // element:
      },
      {
        path:'rides',
        element:<Rides/>
      },
      {
        path:'rides/:id',
        element:<RideInfo/>
      },
      {
        path:'dues',
        element:<Dues/>
      },
      {
        path:'dues/:id',
        element:<DueInfo/>
      },
      {
        path:'payments',
        element:<Payments/>
      },
      {
        path:'payments/:id',
        element:<PaymentInfo/>
      },
      {
        path:'memberships',
        element:<Membership/>
      },
      {
        path:'memberships/:id',
        element:<MembershipInfo/>
      },
      {
        path:'users',
        element:<Users/>
      },
      {
        path:'users/:id',
        element:<UserInfo/>,
        children:[
          {
          path:'/users/:id/',
            index:true,
            element:<UserRides/>
          },
          {
          path:'/users/:id/payments',
            element:<UserPayments/>
          },
          {
          path:'/users/:id/profile',
            element:<UserProfile/>
          }
        ]
      },
      {
        path:'settings',
        element:<Settings/>,
        children:[
          {
            path:'/settings/',
            index:true,
            element:<Preferences/>
          },
          {
            path:'/settings/cities',
            element:<Cities/>
          },
          {
            path:'/settings/brands',
            element:<Brands/>
          },
          {
            path:'/settings/pickup-points',
            element:<PickupPoints/>
          },
          {
            path:'/settings/membership',
            element:<Memebership/>
          }
        ]
      },
      {
        path:'vendors',
        element:<Vendors/>
      },
      {
        path:'vehicles',
        element:<Vehicles/>
      },
      {
        path:'offers',
        element:<Offers/>
      },
      {
        path:'vehicles/:id',
        element:<VehicleInfo/>,
        children:[
          {
            path:'/vehicles/:id/',
            index:true,
            element:<VehicleInformation/>
          },
          {
          path:'/vehicles/:id/rides',
            element:<UserRides/>
          },
          {
          path:'/vehicles/:id/reviews',
            element:<UserPayments/>
          },
          {
          path:'/vehicles/:id/profile',
            element:<UserProfile/>
          }
        ]
      },
      {
        path:'admins',
        element:<Admin/>
      },
      // {
      //   path:'/activities'
      // }
    ]
  },
])

const contextClass = {
  success: "bg-blue-600",
  error: "bg-red-600",
  info: "bg-green-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

const app = initializeApp(firebaseConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <RouterProvider router={router}/>
    </PersistGate>
  </Provider>
  <ToastContainer toastClassName={({ type }) => contextClass[type || "default"] +
        " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
      }/>
</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
