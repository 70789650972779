import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import authAxios from "../../../helpers/axios"

export default function ManageOffer({onClose,onSubmit,updateData,edit})
{
    const [manageData,setManageData] = useState({name:'',email:'',mobile:'',city:''})
    const [cities,setCities] = useState([])
    const formName = 'editOffer';

       useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCities(res.data)
        }
        getCities()
    },[])
    




    return <><Popup title={edit ? 'Update Offer' : 'Create Offer'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div>
                <label>Offer Code*</label>
                <Input type='text' placeholder={'Enter Offer Code'} value={manageData.code} required={true} setValue={(value)=>setManageData(data=>({...data,code:value}))}/>
            </div>
            <div>
                <label>Description</label>
                <Input type='text' placeholder={'Enter Description'} value={manageData.description} required={false} setValue={(value)=>setManageData(data=>({...data,description:value}))}/>
            </div>
            <div>
                <label>Discount Type*</label>
                <Select options={[{label:'Percent',value:'percent'},{label:'Flat',value:'flat'}]} customLabel={'label'} customValue={'value'} placeholder={'Select Discount Type'} value={manageData.discount_type} setValue={(value)=>setManageData(data=>({...data,discount_type:value}))} required={true}/>
            </div>
            <div>
                <label>Discount Value*</label>
                <Input type='number' placeholder={'Enter Discount Value'} value={manageData.discount_value} required={true} setValue={(value)=>setManageData(data=>({...data,discount_value:value}))}/>
            </div>
            <div>
                <label>Valid From*</label>
                <Input type='datetime-local' placeholder={'Select Valid From'} value={manageData.valid_from} required={true} setValue={(value)=>setManageData(data=>({...data,valid_from:value}))}/>
            </div>
            <div>
                <label>Valid To*</label>
                <Input type='datetime-local' placeholder={'Select Valid To'} value={manageData.valid_to} required={true} setValue={(value)=>setManageData(data=>({...data,valid_to:value}))}/>
            </div>
            <div>
                <label>Max Uses</label>
                <Input type='number' placeholder={'Enter Max Uses'} value={manageData.max_uses} required={false} setValue={(value)=>setManageData(data=>({...data,max_uses:value}))}/>
            </div>
            <div>
                <label>Platform Offer</label>
                <Select options={[{label:'Yes',value:true},{label:'No',value:false}]} customLabel={'label'} customValue={'value'} placeholder={'Select Platform Offer'} value={manageData.is_platform_offer} setValue={(value)=>setManageData(data=>({...data,is_platform_offer:value}))}/>
            </div>
            <div>
                <label>Active</label>
                <Select options={[{label:'Yes',value:true},{label:'No',value:false}]} customLabel={'label'} customValue={'value'} placeholder={'Select Status'} value={manageData.is_active} setValue={(value)=>setManageData(data=>({...data,is_active:value}))}/>
            </div>
        </form>
    </Popup>
    {/* {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null} */}
    </>
}