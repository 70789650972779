import React, { useEffect, useState } from 'react'
import RightIcon from '../../images/right.svg'
// import SearchInput from '../../components/SearchInput'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import { getDateFormat, getTimeFormat, getValidDateFormat } from '../../helpers/utils'
import { LIMIT } from '../../helpers/constants'
import SearchInput from '../../components/SearchInput'
import ManageUser from './components/ManageUser'

export default function Users() {

    const [searchText,setSearchText] = useState('')
    const [users,setUsers] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [cityFilter,setCityFilter] = useState('')
    const [sort,setSort] = useState('-createdAt')
    const [premiumFilter,setPremiumFilter] = useState('')
    const [disableExport,setDisableExport] = useState(false)
    const navigate = useNavigate()


    async function getUsers(){

        try 
        {
            let query = `populate=true&offset=${offset}&limit=${LIMIT}&sort=${sort}`
            if(searchText) query+= `&search=${searchText}`
            // if(cityFilter) query+= `&cityId=${cityFilter}`
            // if(premiumFilter) query+= `&isPremium=${premiumFilter}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user?${query}`)
            setUsers(res.data.data)
            setCount(res.data.totalCount)
        } catch (error) {
            ErrorToast(error.response.data.name)
        }
    }
    useEffect(()=>
    {
        getUsers();
    },[searchText,offset,cityFilter,premiumFilter,sort])

    async function createUser(e,data)
    {
        try 
        {
            e.preventDefault();
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/admin/user`,data)
            setOffset(0)
            setCount(0)
            await getUsers();
        } catch (error) {
            ErrorToast(error.response.data.name)
        }
    }


    async function downloadExcel() {
        try {
          // Make a GET request to the backend endpoint
        //   const response = await axios({
        //     url: 'http://localhost:3001/api/download/bookings', // Updated with /api path
        //     method: 'GET',
        //     responseType: 'blob', // Important, ensures that the file is treated as a Blob
        //   });
            setDisableExport(true)
          const response = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/admin/export-user`)
      
          // Create a URL for the blob
          const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create an anchor element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'cocarr-users.csv'); // File name to download
            document.body.appendChild(link);
            link.click();

            // Clean up by removing the link element and revoking the URL
            link.remove();
            window.URL.revokeObjectURL(url);
            setDisableExport(false)
        } catch (error) {
            setDisableExport(false)
            ErrorToast(error.response.data.name)
        }
      };
 


    // useEffect(()=>
    // {
    //     getCities();
    // },[])


  return (
    <>
        <div className='block justify-between max-w-7xl mx-auto'>
        <div className='flex mb-4 justify-end '>
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search user'} label={'Search user'} />
            <div className='flex items-center h-full justify-center text-center border-[#d3d3d3] bg-white rounded-md shadow-sm shadow-gray-200 ml-4'>
                <div
                    className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset > 0) {
                        setOffset(offset - LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                </div>
                <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                <div
                    className='px-2 py-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset + LIMIT < count) {
                        setOffset(offset + LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                </div>
                <div className="w-full ml-4">
                    <select onChange={(e)=>setSort(e.target.value)} className="w-full rounded-md shadow-sm shadow-gray-200 border-[#f3f3f3]">
                        <option value="-createdAt">Joined On (DESC)</option>
                        <option value="createdAt">Joined On (ASC)</option>
                        <option value="-name">Name (DESC)</option>
                        <option value="name">Name (ASC)</option>
                        <option value="-contactNumber">Contact Number (DESC)</option>
                        <option value="contactNumber">Contact Number (ASC)</option>
                    </select>
                </div>
                
                </div>
                <div className='mr-4'>
                    <button type='button' disabled={disableExport} className='btn-md ml-4' onClick={downloadExcel}>Export Users</button>
                </div>
                <div>
                    <button type='button' className='btn-md ml-4' onClick={()=>setShowCreate({status:true,edit:null})}>Add User</button>
                </div>
        </div>
        <div className='flex flex-1 bg-white w-full'>
            <table className=' w-full'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    {/* <td><p>Image</p></td> */}
                    <td onClick={()=>setSort('name:desc')}><p>Name</p></td>
                    <td onClick={()=>setSort('contactNumber:desc')}><p>Emai/Phone</p></td>
                    <td onClick={()=>setSort('createdAt:desc')}><p>Joined On</p></td>
                    <td onClick={()=>setSort('status:desc')}><p>Status</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map((item,index)=>
                        {
                            return <tr key={index} onClick={()=>navigate(`/users/${item.id}/`)}>
                                <td className='capitalize'>
                                <div className='flex w-full'>
                                    <div className='mr-3  w-10 h-10'>
                                        {item.profilePhoto ? <img src={item.profilePhoto} className='w-10 h-10 rounded-full'/> : <div className='bg-gray-400 rounded-full h-10 w-10'></div>}
                                    </div>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.name ? item.name : 'Unavailable'}</p>
                                        <p className='text-xs my-0 text-gray-400'>{getDateFormat(item.createdAt)}</p>
                                    </div>
                                    </div>
                                </td>
                                <td>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.email ? item.email : 'Not Available'}</p>
                                        <p className='text-xs font-regular my-0'>{item.contactNumber ? item.contactNumber : 'Not Available'}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{`${getValidDateFormat(item.createdAt)}`}</p>
                                        <p className='text-xs font-regular my-0'>{getTimeFormat(item.createdAt)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    {/* <button className='btn-xs' onClick={()=>setShowCreate({status:true,edit:item.id})}>Edit</button> */}
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        </div>
        {showCreate.status ? <ManageUser onSubmit={createUser} onClose={()=>setShowCreate({status:false,edit:null})}/> : null}
    </>
  )
}

