import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import RightIcon from '../../images/right.svg'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { InfoToast } from '../../helpers/toasters'
import SearchInput from '../../components/SearchInput'
import { BOOKING_BOOKED, BOOKING_FINISHED, BOOKING_ONGOING, DUE_CREATED, DUE_PAID, LIMIT } from '../../helpers/constants'
import { useParams, useSearchParams } from 'react-router-dom'
import { getDateFormat } from '../../helpers/utils'
import Loader from '../../components/Loader'
import Popup from '../../components/Popup'
import Input from '../../components/Input'

export default function DueInfo() {

    const {id} = useParams()
    const [rideInfo,setRideInfo] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [showStart,setShowStart] = useState(false)
    const [showCancel,setShowCancel] = useState(false)
    const [showEnd,setShowEnd] = useState(false)
    const [loading,setLoading] = useState(true)


    async function getDueInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/due/${id}?populate=true`)
            console.log('data',res.data)
            if(res.data) setRideInfo(res.data)
            setLoading(false)
        } catch (error) {
          setLoading(false)
          InfoToast(error.response.data.error.message)
        }
    }
    useEffect(()=>
    {
        getDueInfo();
    },[])

   
    async function onCancel(e,reason)
    {

        try 
        {
            e.preventDefault();
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/due/cancel/${id}`,{reason})
            console.log('data',res.data)
            if(res.data) await getDueInfo()
            setShowStart(false)
            setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log(error.response.data.error.message)
          InfoToast(error.response.data.error.message)
        }
    }




  return (
    !loading ? <div className='max-w-7xl mx-auto px-6'>

        <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4'>
            <div className='pb-3 border-b border-b-slate-200 flex w-full justify-between'>
            <p className='text-sm font-medium text-[#454545] '>Due Information</p>
            <div className='flex'>
            {rideInfo.status === DUE_CREATED ? <button className='mr-2 btn-xs border border-red-500 text-red-700 hover:bg-red-200 hover:text-red-700' onClick={()=>setShowCancel(true)}>Cancel Due</button> : null}
            </div>
            </div>
            <div className='rounded-xl w-full flex py-0 px-0 overflow-hidden z-10'>
            <div className='h-full flex flex-col w-full'>
              <div className='w-full md:flex justify-between items-center pt-6'>
                <div>
                  <div className='flex items-center'>

                  <div>
                  <p className='text-base font-semibold text-black'>{`${rideInfo.user.name}`}</p>
                  <p className='text-xs text-[#454545] md:mt-1 uppercase'>{rideInfo.user.contactNumber}</p>
                  </div>
                  </div>
                </div>
                <div className='hidden md:block px-4'>
                  <p className='text-xs inline-block font-medium text-black uppercase status-text bg-[#49ab77] rounded-sm px-3 py-2'>{`${rideInfo.status === DUE_CREATED ? 'PENDING' : rideInfo.status === DUE_PAID ? 'PAID' : 'CANCELLED'}`}</p>
                </div>
              </div>

              <div className='mt-2 py-6 md:py-6 md:px-6'>
            <div className='grid md:grid-cols-4 grid-cols-2 gap-y-6 justify-between mt-2 md:mt-4'>
            <div className='pr-4'>
              <div className='flex mt-2'>
                {/* <RideDot/> */}
                <div>
                  <p className='ml-2 text-xs text-[#454545]'>Total Amount</p>
                  <p className='ml-2 text-sm text-[#151515]'>{rideInfo.totalAmount}</p>

                </div>
                </div>
            </div>
            <div className='pr-4'>
              <div className='flex mt-2'>
                <div>
                  <p className='ml-2 text-xs text-[#454545]'>Booking Id</p>
                  <p className='ml-2 text-sm text-[#151515]'>{rideInfo.bookingId ? rideInfo.bookingId : 'Not Available'}</p>

                </div>
                </div>
            </div>
            <div className='pr-4'>
                <div>
                  <p className='ml-2 text-xs text-[#454545]'>Reason</p>
                  <p className='ml-2 text-sm text-[#151515]'>{rideInfo.reason}</p>
                </div>
            </div>
            <div className='pr-4'>
                <div>
                  <p className='ml-2 text-xs text-[#454545]'>Remarks</p>
                  <p className='ml-2 text-sm text-[#151515]'>{rideInfo.remarks}</p>
                </div>
            </div>
              </div>
              </div>

              
              
              <div className='mt-2 py-6 md:py-6 md:px-6 border-t border-t-[#e3e3e3]'>
                  <p className='ml-2 text-xs uppercase font-semibold text-[#454545]'>Payment Information</p>
            <div className='flex mt-2 md:mt-4'>
            <div className='pr-4 w-full'>
              <div className='grid md:grid-cols-4 grid-cols-2 gap-y-4 md:gap-0 mt-2 justify-between'>
                <div>
                  <p className='ml-2 text-xs text-[#454545]'>Status</p>
                  <p className='ml-2 text-sm text-[#151515] capitalize'>{rideInfo.transaction ? rideInfo.transaction?.paymentStatus : 'Not available'}</p>
                </div>
                <div>
                  <p className='ml-2 text-xs text-[#454545]'>Payment Method</p>
                  <p className='ml-2 text-sm text-[#151515] uppercase'>{rideInfo.transaction ? rideInfo.transaction?.paymentMethod : 'Not available'}</p>
                </div>
                <div>
                  <p className='ml-2 text-xs text-[#454545]'>Amount</p>
                  <p className='ml-2 text-sm text-[#151515]'>{rideInfo.totalAmount}</p>
                </div>
                </div>
            </div>
              </div>
              </div>
              </div>
        </div>
        </div>
        {  showCancel? <CancelPopup onClose={()=>setShowCancel(false)} onCancel={onCancel}/> : null}
    </div> : <Loader/>
  )
}




const RefundInformation = ({rideInfo})=>
{
    return               <div className='mt-2 py-6 md:py-6 md:px-6 border-t border-t-[#353535]'>
    <p className='ml-2 text-xs uppercase font-semibold text-[#454545]'>Refund Information</p>
    <div className='flex mt-2 md:mt-4'>
    <div className='pr-4 w-full'>
    <div className='grid md:grid-cols-4 grid-cols-2 gap-y-4 md:gap-0 mt-2 justify-between'>
      <div>
        <p className='ml-2 text-xs text-[#454545]'>Payment Id</p>
        <p className='ml-2 text-sm text-[#fff] capitalize'>{rideInfo.refunds.paymentId}</p>
      </div>
      <div>
        <p className='ml-2 text-xs text-[#454545]'>Refund Method</p>
        <p className='ml-2 text-sm text-[#fff] uppercase'>{rideInfo.transaction.paymentMethod ? rideInfo.transaction.paymentMethod : 'Not available'}</p>
      </div>
      <div>
        <p className='ml-2 text-xs text-[#454545]'>Status</p>
        <p className='ml-2 text-sm text-[#fff] capitalize'>{rideInfo.refunds.status}</p>
      </div>
      <div>
        <p className='ml-2 text-xs text-[#454545]'>Amount</p>
        <p className='ml-2 text-sm text-[#fff]'>Rs.{rideInfo.refunds.amount}</p>
      </div>
      </div>
    </div>
    </div>
    </div>
}

const CancelPopup = ({onClose,onCancel})=>
{
    const [startData,setStartData] = useState({reason:''})
    return <Popup title={'Cancel Due'} onClose={onClose} formName={'cancelDue'} submitTitle={'Cancel Due'} onSubmittingTitle='Cancelling Due...'>
        <form name='cancelDue' id="cancelDue" onSubmit={(e)=>onCancel(e,startData.reason)}>
        <div className="flex w-full gap-4">
            <div className='w-full'>
                <label>Cancellation Reason</label>
                <textarea className='text-input resize-none w-full' placeholder={'Enter cancellation reason'} value={startData.reason} required={true} onChange={(e)=>setStartData(data=>({...data,reason:e.target.value}))}/>
            </div>
        </div>
        </form>
    </Popup>
}
